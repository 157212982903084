input[type=email],
input[type=text],
input[type=password],
input[type=tel],
textarea,
select,
.mfp-content input[type=email] f {
  background-color: #fff;
  border: #DBDADA 1px solid;
  border-radius: 2px;
  color: #0080bc;
  font-family: 'Din Pro', sans-serif;
  font-weight: normal;
  padding: 5px 7px;
  font-size: 15px;
}
::placeholder {
  color: #7a7a7a;
  opacity: 1;
}
.powermail_confirmation .btn-group form + form {
  padding-left: 70px;
}
.tx-powermail form {
  margin-bottom: 15px;
}
.tx-powermail form h3 {
  font-size: 15px;
}
/* Errors */
.tx-powermail form .parsley-errors-list,
section > .wrapper .tx-powermail form .parsley-errors-list {
  padding-left: 0;
  background-color: #ffffff;
  border: none;
  margin-top: 0;
}
.tx-powermail form .parsley-errors-list li {
  background-color: #c9282d;
}
.tx-powermail .powermail_fieldset .parsley-error {
  background-color: #ffffff;
}
.tx-powermail .powermail_fieldset .parsley-errors-list li {
  background-color: #f32837;
  color: #ffffff;
}
.tx-powermail .powermail_fieldset .powermail_field_error_container .parsley-errors-list {
  background-color: #ffffff;
}
.tx-powermail .powermail_fieldset .powermail_field_error_container .parsley-errors-list > li {
  padding: 5px 10px;
}
.tx-powermail .error:hover,
.tx-powermail .error:focus,
.tx-powermail .error:active,
.tx-powermail .parsley-error:hover,
.tx-powermail .parsley-error:focus,
.tx-powermail .parsley-error:active {
  border-color: #c9282d;
}
/* Fields */
.powermail_fieldwrap {
  clear: both;
  position: relative;
  font-size: 0;
  margin-bottom: 15px;
}
.powermail_fieldwrap > * {
  font-size: 15px;
}
.powermail_fieldwrap label:first-child,
.powermail_fieldwrap .powermail_field {
  display: inline-block;
  vertical-align: top;
}
.powermail_fieldwrap label:first-child,
.powermail_fieldwrap p:first-child {
  width: 40%;
}
.powermail_fieldwrap .powermail_field {
  width: 60%;
}
.powermail_fieldwrap input[type="email"],
.powermail_fieldwrap input[type="text"],
.powermail_fieldwrap input[type="password"],
.powermail_fieldwrap input[type="tel"],
.powermail_fieldwrap textarea,
.powermail_fieldwrap select,
.powermail_fieldwrap p:last-child {
  display: inline-block;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  font-size: 13px;
  vertical-align: top;
  width: 100%;
}
.powermail_fieldwrap textarea {
  resize: vertical;
}
/* Submit */
.powermail_fieldwrap.powermail_fieldwrap_type_submit .powermail_field {
  width: 100%;
}
.tx_publicationmanager_vhw_notes {
  height: 150px;
  display: none !important;
}
